import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { useWindowWidth } from '@distribute/frontend/utils';
import { pagesModel } from '../../../../features/pages';
import { useSelector } from 'react-redux';
import { APP_ORIGIN } from '../../../../shared/config';
import { teamsModel } from '../../../../features/teams';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { Page } from '@distribute/shared/types';

const LAYOUT_ELEMENTS_HEIGHT = 122;
const PREVIEW_PADDING_SIZE = 64;
const BORDER_WIDTH = 16;

const LOCAL_SUBDOMAIN = 'dev';

const getPreviewSrc = (subdomain: string, pageId: string | number) => {
  if (subdomain === LOCAL_SUBDOMAIN) {
    return `http://${LOCAL_SUBDOMAIN}.${APP_ORIGIN}/draft/${pageId}`;
  }
  return `https://${subdomain}.${APP_ORIGIN}/draft/${pageId}`;
};

type IframeSizes = {
  width: number | '100%';
  height: number;
};

const tabletSizes: IframeSizes = {
  width: 768 + BORDER_WIDTH * 2,
  height: 1024 + BORDER_WIDTH * 2,
};

const mobileSizes: IframeSizes = {
  width: 375 + BORDER_WIDTH * 2,
  height: 800 + BORDER_WIDTH * 2,
};

type Props = {
  currentPageOrTemplate: Page | TemplateExtended;
};

export const PreviewPage: React.FC<Props> = ({ currentPageOrTemplate }) => {
  const {
    id: pageId,
    content: { title },
  } = currentPageOrTemplate;
  const { domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const { windowHeight, isMobile } = useWindowWidth();
  const activePreview = useSelector(
    pagesModel.selectors.selectEditorPreviewMode
  );

  const desktopPreview = activePreview === 'Desktop';
  const tabletPreview = activePreview === 'Tablet';

  const blockHeight =
    desktopPreview || isMobile
      ? windowHeight - LAYOUT_ELEMENTS_HEIGHT
      : windowHeight - LAYOUT_ELEMENTS_HEIGHT - PREVIEW_PADDING_SIZE;

  const getIframeSizes = (): IframeSizes => {
    if (desktopPreview || isMobile) {
      return { width: '100%', height: blockHeight };
    }
    if (tabletPreview) {
      return tabletSizes;
    }

    return mobileSizes;
  };
  const iframeSizes = getIframeSizes();

  const scale = blockHeight / iframeSizes.height;

  return (
    <div className="flex flex-col h-full grow-1">
      <div
        className={classNames(
          'flex flex-col justify-center grow-1 h-full sm:p-0',
          {
            'py-8': !desktopPreview,
          }
        )}
      >
        <div
          className={classNames('flex flex-col grow-1 w-full relative h-full')}
        >
          <iframe
            key={activePreview}
            allow="clipboard-read; clipboard-write;"
            className={classNames(`sm:border-none sm:rounded-none grow-1`, {
              'border-16 border-gray-900 rounded-lg absolute left-1/2 top-1/2 -translate-x-1/2 transform -translate-y-1/2':
                !desktopPreview && !isMobile,
            })}
            height={iframeSizes.height}
            width={iframeSizes.width}
            style={
              {
                '--tw-scale-x': scale,
                '--tw-scale-y': scale,
              } as CSSProperties
            }
            title={title || 'Untitled'}
            src={getPreviewSrc(currentTeamDomain, pageId)}
          />
        </div>
      </div>
    </div>
  );
};
