import { isInFrame } from '@distribute/frontend/utils';
import { TeamFiltered } from '@distribute/shared/types';
import { Location } from 'history';
import {
  CancelledEffect,
  call,
  cancelled,
  fork,
  takeEvery,
} from 'redux-saga/effects';
import { analyticsGlobalWorker } from '../../entities/analytics';
import { authModel } from '../../entities/auth-operation';
import { setUserData } from '../../entities/auth-user';
import { checkEmailVerification } from '../../entities/auth-user/lib/checkEmailVerification';
import { initGleapBlock } from '../../entities/gleap';
import { redirect, redirectActions } from '../../entities/history';
import { acceptTeamInvitationModel } from '../../features/accept-team-invitation';
import { signOut } from '../../features/auth/log-out';
import { contactSupportFormModel } from '../../features/contact-support-form';
import { extensionModel } from '../../features/extension';
import { mediaUploadModel } from '../../features/media-upload';
import { onboardingModel } from '../../features/onboarding';
import { publicTemplatesModel } from '../../features/public-template';
import { subscriptionModel } from '../../features/subscription';
import { teamInfoSettingsModel } from '../../features/team-info-settings';
import { teamMembersModel } from '../../features/team-members';
import { teamsModel } from '../../features/teams';
import { workspaceWorker } from '../../pages/workspace/model';
import {
  clearData,
  onHistory,
  onNavigate,
  privateRouteHandlers,
} from '../routing';
import { acceptPageInvitationModel } from '../../features/accept-page-invitation';
import { AcceptPageInvitationResponseType } from '@distribute/shared/api-types/team';
import { requestToEditPageModel } from '../../features/request-to-edit-page';
import { checkContinueUrl } from '../../pages/workspace/model/sagas';

export function* handleSignedIn() {
  yield fork(workspaceWorker);
  yield fork(extensionModel.sagas.extensionWorker);

  yield takeEvery(authModel.actions.signOutClick, signOut);
  yield takeEvery(redirectActions.toSettingsClick, redirect.toSettings);
  yield takeEvery(
    redirectActions.toSubscriptionsClick,
    redirect.toSubscription
  );
  yield takeEvery(redirectActions.toWorkspace, redirect.toWorkspace);
  yield takeEvery(redirectActions.toOnboardingClick, redirect.toOnboarding);

  yield takeEvery(redirectActions.toWorkspaceFolder, function* ({ payload }) {
    yield call(redirect.toWorkspaceFolder, {
      sequenceNumber: payload.sequenceNumber,
    });
  });

  yield takeEvery(redirectActions.toWorkspaceByBackUrl, function* () {
    yield call(redirect.toWorkspaceByBackUrl);
  });

  yield takeEvery(
    redirectActions.fromWorkspaceWithBackUrl,
    function* ({ payload }) {
      yield call(redirect.fromWorkspaceWithBackUrl, {
        path: payload.path,
      });
    }
  );

  yield takeEvery(redirectActions.toEditorPage, function* ({ payload }) {
    yield call(redirect.toEditorPage, {
      sequenceNumber: payload.sequenceNumber,
    });
  });
  yield takeEvery(
    mediaUploadModel.actions.uploadFile,
    mediaUploadModel.sagas.uploadFile
  );

  yield call(setUserData);

  const teamInviteAcceptedTeam: TeamFiltered | undefined = yield call(
    acceptTeamInvitationModel.sagas.handleAcceptTeamInvitationSignedIn
  );

  const pageInviteResponse: AcceptPageInvitationResponseType | undefined =
    yield call(
      acceptPageInvitationModel.sagas.handleAcceptPageInvitationSignedIn
    );

  yield call(
    requestToEditPageModel.sagas.handleAcceptRequestToEditPageSignedIn
  );

  yield call(
    requestToEditPageModel.sagas.handleDeclineRequestToEditPageSignedIn
  );

  yield call(checkEmailVerification);

  yield fork(teamsModel.sagas.refreshTeamDataWatcher);

  yield call(
    teamsModel.sagas.setTeamsData,
    teamInviteAcceptedTeam ?? pageInviteResponse?.team
  );

  const isOnboardingFinished: boolean = yield call(
    onboardingModel.sagas.handleOnboarding
  );

  if (teamInviteAcceptedTeam) {
    yield fork(redirect.toWorkspaceDefaultFolder);
  }

  if (pageInviteResponse?.team && !pageInviteResponse.shouldVerifyEmail) {
    yield fork(redirect.toEditorPage, {
      sequenceNumber: pageInviteResponse.pageSequenceNumber,
    });
  }

  yield call(
    publicTemplatesModel.sagas.handlePublicTemplates,
    isOnboardingFinished
  );

  if (!isInFrame()) {
    yield call(initGleapBlock);
  }

  yield fork(subscriptionModel.sagas.subscriptionGlobalWorker);
  yield fork(teamMembersModel.sagas.teamMembersGlobalWorker);
  yield fork(teamsModel.sagas.teamsGlobalWorker);
  yield fork(teamInfoSettingsModel.sagas.teamInfoSettingsGlobalWorker);
  yield fork(contactSupportFormModel.sagas.contactSupportFormGlobalWorker);
  yield fork(analyticsGlobalWorker);

  yield call(checkContinueUrl);

  try {
    yield call(
      onHistory,
      function* (location: Location) {
        yield call(onNavigate, location, privateRouteHandlers, redirect.to404);
      },
      'Private'
    );
  } finally {
    const isCancel: CancelledEffect = yield cancelled();
    if (isCancel) {
      yield call(clearData);
    }
  }
}
