import YPartyKitProvider from 'y-partykit/provider';
import { useMemo } from 'react';
import { authUserModel } from '../../auth-user';
import { useSelector } from 'react-redux';
import { COLLABORATION_SERVER_URL } from '../../../shared/config';

type Props = {
  pageId: string;
};

export function useCollaborationProvider({ pageId }: Props) {
  const token = useSelector(authUserModel.selectors.selectAuthToken);

  return useMemo(() => {
    if (!token)
      throw new Error(
        'Unexpected condition: no access token provided to provider.'
      );
    return new YPartyKitProvider(COLLABORATION_SERVER_URL, pageId, undefined, {
      params: { token },
    });
  }, [pageId, token]);
}
