import { Snippet } from '@distribute/shared/types';
import { EditorContent, useEditor } from '@tiptap/react';
import { FeatureFlags } from '../../../../entities/feature-flag';
import { getMainEditorExtensions } from '../../../../entities/tiptap-editor/mainEditorExtensions';
import { FC } from 'react';
import { EditorHtmlContent } from '../../../../pages/editor/ui/components/EditorHtmlContent';

type Props = {
  snippet: Snippet;
};

export const TextSnippetView: FC<Props> = ({ snippet }) => {
  const readonlyEditor = useEditor({
    extensions: getMainEditorExtensions(),
    editorProps: {
      attributes: {
        class:
          'main-editor main-editor-readonly inside-editor snippet-text-readonly',
      },
    },
    content: snippet.content,
    editable: false,
  });

  return FeatureFlags.HTML_CONTENT ? (
    <EditorHtmlContent htmlContent={snippet.htmlContent} />
  ) : (
    <EditorContent editor={readonlyEditor} />
  );
};
