import React, { useState } from 'react';
import { Button, Icon, Tag } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Page, Folder } from '@distribute/shared/types';
import { navigate } from '../../../../processes/navigation';
import {
  GETTING_STARTED_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_ROUTE,
} from '../../../../entities/history';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  QUERY_PARAM_IS_SHOW_TEMPLATES_MODAL,
  pagesModel,
  usePagePermissions,
} from '../../../../features/pages';
import { EditorHeaderToolbar } from './EditorHeaderToolbar';
import { PreviewBar } from './PreviewBar';
import cn from 'classnames';
import { useWindowWidth } from '@distribute/frontend/utils';
import { PageSettingsDropdown } from './PageSettingsDropdown';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { foldersModel } from '../../../../features/folders';
import {
  getTemplatesRoute,
  templatesModel,
} from '../../../../features/templates';
import { getQueryParam } from '../../../../shared/lib';
import { checkIsGettingStartedAvailable } from '../../../../features/getting-started/lib';
import { teamsModel } from '../../../../features/teams';
import { useUserAgentDevices } from '../../../../shared/hooks/useUserAgentDevices';
import { TemplateStatusTag } from './TemplateStatusTag';

type EditorHeaderProps = {
  currentPageOrTemplate: Page | TemplateExtended;
  isTemplateMode: boolean;
};

export const EditorHeader: React.FC<EditorHeaderProps> = ({
  currentPageOrTemplate,
  isTemplateMode,
}) => {
  const dispatch = useDispatch();
  const [isShowTemplatesModal] = useState(
    getQueryParam(QUERY_PARAM_IS_SHOW_TEMPLATES_MODAL) === 'true'
  );

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const { isCanEditDocumentContent } = usePagePermissions(
    isTemplateMode ? undefined : (currentPageOrTemplate as Page)
  );

  const currentFolder = useSelector(
    foldersModel.selectors.selectCurrentFolder
  ) as Folder;
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isRedirectToGettingStartedOnBack =
    isShowTemplatesModal && checkIsGettingStartedAvailable(currentTeam);

  const onBackClick = () => {
    if (isTemplateMode) {
      const to = getTemplatesRoute(currentPageOrTemplate as TemplateExtended);

      dispatch(navigate({ to }));

      dispatch(templatesModel.actions.setCurrentTemplate(undefined));
      dispatch(templatesModel.actions.setCurrentTemplateContent(undefined));
    } else {
      if (isEditorPreview) {
        dispatch(pagesModel.actions.setIsEditorPreview(false));
      } else {
        let to = currentPageOrTemplate
          ? generatePath(WORKSPACE_FOLDER_ROUTE, {
              sequenceNumber: currentFolder.sequenceNumber,
            })
          : WORKSPACE_ROUTE;

        if (isRedirectToGettingStartedOnBack) {
          to = GETTING_STARTED_ROUTE;
        }

        dispatch(navigate({ to, isKeepQueryParams: false }));
      }
    }
  };

  const handlePreviewExit = () => {
    dispatch(pagesModel.actions.setIsEditorPreview(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
  };

  const [isRequestToEditSent, setRequestToEditSent] = useState(false);

  const handleRequestToEdit = () => {
    setRequestToEditSent(true);
    dispatch(pagesModel.actions.requestToEditPage({}));
  };

  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  return (
    <div className="sticky top-0 z-50" id="top-bar">
      <header className="flex justify-between items-center px-4 pl-3 py-3 sm:py-2 bg-base-white border-b border-gray-200 transition-all h-16">
        <div
          className={cn('flex justify-between items-center', {
            'max-w-1/2': !isEditorPreview,
            'max-w-1/3 flex-1': isEditorPreview,
            'flex-1': !isCanEditDocumentContent,
          })}
        >
          <div
            className={cn('pr-2', {
              'border-r border-gray-200':
                isTemplateMode || (currentPageOrTemplate as Page).published,
            })}
          >
            <Button
              className="md:pl-2"
              size="sm"
              color="tertiary-black"
              variant="icon-text"
              onClick={onBackClick}
            >
              <span className="flex items-center gap-2">
                <Icon glyph={IconMap.ChevronLeft} width={20} />
                <span className="text-s max-w-50 truncate md:hidden">
                  {isTemplateMode ? 'Back' : currentFolder?.title}
                </span>
              </span>
            </Button>
          </div>
          <div className="flex items-center gap-2 pl-3 flex-1 min-w-0">
            {!isTemplateMode && (currentPageOrTemplate as Page).published && (
              <Tag
                color="green"
                className="!py-0.25 !px-2.5 rounded-full font-medium border border-success-200"
              >
                Public
              </Tag>
            )}
            {isTemplateMode && <TemplateStatusTag />}
          </div>
        </div>
        {(isTemplateMode ||
          (!isEditorPreview &&
            isCanEditDocumentContent &&
            !isMobile &&
            !isTablet)) && (
          <EditorHeaderToolbar isTemplateMode={isTemplateMode} />
        )}

        {(isEditorPreview || !isCanEditDocumentContent) &&
          !isMobile &&
          !isTablet &&
          !isTemplateMode && (
            <>
              <PreviewBar />
              {!isTablet && isCanEditDocumentContent && (
                <div className="flex-1">
                  <Button
                    variant="text"
                    color="secondary"
                    size="md"
                    onClick={handlePreviewExit}
                    className="text-sm text-gray-700 font-semibold ml-auto"
                  >
                    Exit Preview
                  </Button>
                </div>
              )}
              {!isCanEditDocumentContent && (
                <div className="flex-1">
                  <Button
                    variant="text"
                    color="secondary"
                    size="sm"
                    onClick={handleRequestToEdit}
                    className="text-sm text-gray-700 font-semibold ml-auto"
                    disabled={isRequestToEditSent}
                  >
                    {isRequestToEditSent ? 'Request sent' : ' Ask to edit'}
                  </Button>
                </div>
              )}
            </>
          )}

        {(isMobile || isTablet) && !isTemplateMode && (
          <div className="flex items-center shrink-0 gap-4">
            {!isMobile && <PreviewBar />}
            <PageSettingsDropdown listStyles="mt-4" align="end" />
          </div>
        )}
      </header>
    </div>
  );
};
