import React, { useContext } from 'react';
import { createContext } from 'react';
import * as Y from 'yjs';

type CollaborationContextType = {
  doc: Y.Doc | null;
};

type CollaborationProviderProps = {
  children: React.ReactNode;
};

export const CollaborationContext = createContext<CollaborationContextType>({
  doc: new Y.Doc(),
});

export const CollaborationProvider: React.FC<CollaborationProviderProps> = ({
  children,
}) => {
  const doc = new Y.Doc();
  return (
    <CollaborationContext.Provider value={{ doc }}>
      {children}
    </CollaborationContext.Provider>
  );
};

export const CollaborationConsumer = CollaborationContext.Consumer;

export const useCollaboration = () => useContext(CollaborationContext);
