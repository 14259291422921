import { pagesModel, usePagePermissions } from '../../../../features/pages';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { PageSettingsDropdown } from './PageSettingsDropdown';
import { navigate } from '../../../../processes/navigation';
import { generatePath } from 'react-router-dom';
import { WORKSPACE_RECENT_VIEWERS_ROUTE } from '../../../../entities/history';
import { SharePageModal } from './share-page-modal/SharePageModal';
import { teamsModel, useTeamPermissions } from '../../../../features/teams';

export const PUBLISH_BUTTON_ID = 'publish-button-id';

export const PagePublishingHeaderControls: React.FC = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const pagePermissions = usePagePermissions(currentPage);

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const teamUsersInviteAccepted = useMemo(
    () => teamUsers.filter((user) => !user.isWaitingForInviteAcceptance),
    [teamUsers]
  );

  const { isGuest } = useTeamPermissions();

  const handleEditorPreview = () => {
    dispatch(pagesModel.actions.setIsEditorPreview(true));
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
  };

  const onOpenAnalytics = useCallback(() => {
    dispatch(
      navigate({
        to: generatePath(WORKSPACE_RECENT_VIEWERS_ROUTE, {
          sequenceNumber: currentPage.sequenceNumber,
        }),
      })
    );
  }, [currentPage.sequenceNumber, dispatch]);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const isHideSharingButton = useMemo(() => {
    const isInviteMembersTabAvailable =
      pagePermissions.isCanManageAccess && teamUsersInviteAccepted.length > 1;

    if (!isInviteMembersTabAvailable && !pagePermissions.isCanPublishPage) {
      return true;
    }

    return false;
  }, [
    pagePermissions.isCanManageAccess,
    teamUsersInviteAccepted.length,
    pagePermissions.isCanPublishPage,
  ]);

  return (
    <div className="flex items-center justify-between gap-2">
      <SharePageModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
      {!isGuest && <PageSettingsDropdown listStyles="mt-4" />}
      {!currentPage.published && (
        <Button
          variant="icon-text"
          color="secondary"
          title="Preview"
          onClick={handleEditorPreview}
          size="sm"
        >
          <Icon glyph={IconMap.Eye} width={20} className="mr-2 text-gray-700" />
          <span className="text-gray-700 font-semibold">Preview</span>
        </Button>
      )}
      {currentPage.published && (
        <>
          {!isGuest && (
            <Tooltip
              trigger={
                <Button
                  variant="icon"
                  color="secondary"
                  title="Page analytics"
                  size="sm"
                  onClick={onOpenAnalytics}
                >
                  <Icon
                    glyph={IconMap.BarChart01}
                    width={20}
                    className="text-gray-600"
                  />
                </Button>
              }
              sideOffset={4}
            >
              <p className="px-2 py-1 font-semibold text-xs">Page analytics</p>
            </Tooltip>
          )}
          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="secondary"
                title="Preview"
                onClick={handleEditorPreview}
                size="sm"
              >
                <Icon
                  glyph={IconMap.Eye}
                  width={20}
                  className="text-gray-600"
                />
              </Button>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">Preview</p>
          </Tooltip>
        </>
      )}

      {!isGuest && (
        <Button
          variant="icon-text"
          color="primary"
          title="Share"
          onClick={() => {
            setIsShareModalOpen(true);
          }}
          size="sm"
          className={cn({
            hidden: isHideSharingButton,
          })}
        >
          <Icon glyph={IconMap.Share07} width={20} className="mr-2" />
          <span className="font-semibold">Share</span>
        </Button>
      )}
    </div>
  );
};
